import { Link } from "gatsby"
import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import Footer from "../../components/footer"
import Head from "../../components/header"
import Layout from "../../components/layout"
import TopMenu from "../../components/menu"

const extlink = (url, text) => (
  <>
    <a href={url} className="text-decoration-none me-1" target="_blank" rel="noopener noreferrer">{text}</a>
  </>
)

const MediaPage = () => (
  <Layout>
    <Head pageTitle="Media Coverage - GAZIRU, Inc." pageLang="en" />
    <TopMenu />
    <Container>
      <Row>
        <Col sm={1}>
        </Col>
        <Col sm={10}>
          <h4>Media Coverage</h4>
          <h5>01/25/2023</h5>
          <p>An article about our company was published in Nihon Keizai Shimbun (Paper version).</p>
          <hr />
          <h5>06/20/2022</h5>
          <p>An article about our company was published in Nihon Keizai Shimbun (Paper version).</p>
          <hr />
          <h5>08/03/2021</h5>
          <p>An article describing our image recognition technology was published in
            {extlink("https://www.nikko-pb.co.jp/products/detail.php?product_id=5110",
              " Nippon Kogyo Shuppan, Image Labo, August 2021")}.</p>
          <hr />
          <h5>05/18/2021</h5>
          <p>An article about our company was published in
            {extlink("https://japan.zdnet.com/article/35170886/",
              " ZDnet")}.</p>
          <hr />
          <h5>04/26/2021</h5>
          <p>An article about our company was published in
            {extlink("https://www.nikkei.com/article/DGXZQOUC132VZ0T10C21A4000000/",
              " Nikkei Sangyo Shimbun (Web version)")}.</p>
          <hr />
          <h5>04/20/2021</h5>
          <p>An article about our company was published in Nikkei Sangyo Shimbun (Paper version).</p>
          <hr />
          <h5>10/19/2020</h5>
          <p>Our product is featured in {extlink("https://ascii.jp/elem/000/004/029/4029516/",
            " ASCII.jp")}.</p>
          <hr />
          <h5>09/14/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://news.mynavi.jp/article/20200908-1289094/",
              " Mynavi News")}.</p>
          <hr />
          <h5>09/14/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://techable.jp/archives/137179",
              " TECHABLE")}.</p>
          <hr />
          <h5>09/14/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://www.excite.co.jp/news/article/Dreaminnovation_vent_news_cTOlDnWvsE/",
              " Excite News")}.</p>
          <hr />
          <h5>09/08/2020</h5>
          <p>Our press release information was featured in
            {extlink("https://www.e-logit.com/loginews/2016:102105.php",
              " ZAIKEI Shimbun")}.</p>
          <hr />
          <h5>09/08/2020</h5>
          <p>Press release:
            {extlink("https://prtimes.jp/main/html/rd/p/000000001.000062152.html",
              " PR Times")}.</p>
          <hr />
          <h5>07/21/2020</h5>
          <p>Featured in {extlink("https://www.nikkei.com/article/DGKKZO61751380Q0A720C2TJ1000/",
            " Nihon Keizai Shimbun")}.</p>
          <hr />
          <h5>07/20/2020</h5>
          <p> Selected for the first round of applications for
            {extlink("https://co-hr-innovation.jp/",
              " Ministry of Economy, Trade and Industry(METI)'s program")}</p>
          <hr />
          <br />
          <p><Link className="text-decoration-none" to="/">Home</Link></p>

        </Col>
        <Col sm={1}>
        </Col>
      </Row>
    </Container>
    <Footer />
  </Layout>
)

export default MediaPage
